export default {
  menu: [
    {
      label: "Usuarios",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/users",
    },
    {
      label: "Operadoras",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/operadoras",
    },
  ],
};
